import React from 'react';

const AboutPage = () => {
  return (
    <>
      <div>AboutPage</div>
    </>
  );
};

export default AboutPage;
